<template>
    <v-container fluid style="margin-bottom: 100px">
        <v-row>
            <v-col cols="12">
                <v-breadcrumbs :items="breadcumbs">
                    <template v-slot:item="{ item }">
                        <v-breadcrumbs-item
                            :to="item.href"
                            class="text-subtitle-2 crumb-item"
                            :disabled="item.disabled"
                            exact
                        >
                            {{ item.text }}
                        </v-breadcrumbs-item>
                    </template>
                </v-breadcrumbs>
                <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
                    <v-layout align-center pr-4>
                        <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
                        <v-layout column>
                            <div>
                            <strong>{{ snackbar.title }}</strong>
                            </div>
                            <div>{{ snackbar.text }}</div>
                        </v-layout>
                    </v-layout>
                    <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
                        <v-icon>clear</v-icon>
                    </v-btn>
                </v-snackbar>
            </v-col>
        </v-row>
        
        <v-row class="mt-1 mb-1"> 
            <v-col cols="12">
                <v-card outlined>
                    <v-container fluid>
                        <v-row>
                            <v-col class="col-12" sm="6" md="3" lg="3">
                                <div class="d-flex text-center">
                                    <v-radio-group
                                        v-model="invoice" row class="mt-2 pa-0" hide-details=true>
                                        <v-radio label="By Date Inv" value="byDateInv"></v-radio>
                                    </v-radio-group>   
                                    <v-radio-group v-model="invoice" row class="mt-2 pa-0" hide-details=true>
                                        <v-radio
                                            label="By Due Date" value="byDueDate"></v-radio>
                                    </v-radio-group>
                                </div>
                            </v-col>
                            <v-col class="col-12" sm="6" md="2" lg="2">
                                <v-autocomplete  
                                    solo
                                    clearable 
                                    v-model="vendor_name" 
                                    :items="vendors" 
                                    item-value="vendor_id" 
                                    item-text="vendor_name" 
                                    label="VENDOR" 
                                    class="ma-0 pa-0 border-12"
                                    hide-details=true>
                                </v-autocomplete>
                            </v-col>
                            <v-col class="col-12" sm="6" md="2" lg="2">
                                 <v-menu ref="modal" v-model="modal" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
                                    <template v-slot:activator="{ on, attr }">
                                    <v-text-field solo v-model="date_from" label="Date from" prepend-inner-icon="mdi-calendar" clearable readonly class="ma-0 pa-0 border-12" hide-details=true v-bind="attr" v-on="on"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="date_from" no-title @input="modal = false"></v-date-picker>
                                 </v-menu>
                            </v-col>
                            <v-col class="col-12" sm="6" md="2" lg="2">
                                <v-menu ref="modal_to" v-model="modal_to" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">   
                                    <template v-slot:activator="{ on, attrs }">
                                    <v-text-field solo v-model="date_to" label="Date to" prepend-inner-icon="mdi-calendar" clearable readonly v-bind="attrs" v-on="on" class="ma-0 pa-0 border-12" hide-details=true></v-text-field>
                                    </template>
                                  <v-date-picker v-model="date_to" no-title @input="modal_to = false"></v-date-picker>
                                     </v-menu>
                            </v-col>
                            <v-col class="col-12" sm="6" md="2" lg="1">
                                <v-autocomplete  solo clearable v-model="paid_flag" :items="statuses" item-value="paid_flag" item-text="status" label="PAID FLAG" class="ma-0 pa-0 border-12" hide-details=true></v-autocomplete>
                            </v-col>
                            <v-col class="col-12" sm="6" md="2" lg="2">
                                <v-autocomplete
                                    prepend-inner-icon="mdi-flag"
                                    clearable
                                    dense
                                    solo
                                    v-model="raw"
                                    :items="raws"
                                    item-value="value"
                                    :item-text="item => item.raw"
                                    label="Material Flag"
                                    class="ma-0 pa-0 border-12"
                                    hide-details=true
                                ></v-autocomplete>
                            </v-col>
                            <v-col class="col-12" sm="6" md="2" lg="2">
                                <v-btn class="border-12" style="padding: 20px;"
                                    color="info" elevation="2" small @click="submit()"><v-icon>mdi-magnify</v-icon> Search</v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>
        </v-row>
        <v-row align="center" justify="center" class="mb-12">
            <v-col class="col-12" sm="6" md="3">
                <v-card class="rounded-l" color="#e8ecef" outlined height="370">
                    <v-card-title class="mb-0 pb-0">
                        <v-icon color="primary" class="mr-3">
                            mdi-sigma
                        </v-icon>
                        Summary Hutang Report
                        <v-spacer></v-spacer>
                    </v-card-title>
                    <v-card-text>
                        <v-divider class="mb-0 pb-0"></v-divider>
                        <v-list dense two-line class="ma-0 pl-0 pr-0 pt-0 pb-0 mt-3" color="#e8ecef">
                            <v-list-item class="pl-0 pr-0" style="min-height: 40px !important;">
                                <v-list-item-content class="pt-0 pb-0">
                                    <div class="d-flex">
                                        <v-list-item-subtitle class="font-12" style="font-size: 24px;">Total Invoice</v-list-item-subtitle>
                                        <v-spacer></v-spacer>
                                        <v-list-item-title class="primary--text" style="font-size: 15px !important;text-align: right;font-weight: 600;">
                                            {{$store.getters.convertToCurrencyNoFrac(summaryInv ? summaryInv.total_inv : 0)}}
                                        </v-list-item-title>
                                    </div>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item class="pl-0 pr-0" style="min-height: 40px !important;">
                                <v-list-item-content class="pt-0 pb-0">
                                    <div class="d-flex">
                                        <v-list-item-subtitle class="font-12" style="font-size: 24px;">Total Invoice Hutang</v-list-item-subtitle>
                                        <v-spacer></v-spacer>
                                        <v-list-item-title class="primary--text" style="font-size: 15px !important;text-align: right;font-weight: 600;">
                                            {{$store.getters.convertToCurrencyNoFrac(summaryInv ? summaryInv.total_inv_hutang : 0)}}
                                        </v-list-item-title>
                                    </div>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item class="pl-0 pr-0" style="min-height: 40px !important;">
                                <v-list-item-content class="pt-0 pb-0">
                                    <div class="d-flex">
                                        <v-list-item-subtitle class="font-12" style="font-size: 24px;">Total Invoice Paid</v-list-item-subtitle>
                                        <v-spacer></v-spacer>
                                        <v-list-item-title class="primary--text" style="font-size: 15px !important;text-align: right;font-weight: 600;">
                                            {{$store.getters.convertToCurrencyNoFrac(summaryInv ? summaryInv.total_inv_paid : 0)}}
                                        </v-list-item-title>
                                    </div>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item class="pl-0 pr-0" style="min-height: 40px !important;">
                                <v-list-item-content class="pt-0 pb-0">
                                    <div class="d-flex">
                                        <v-list-item-subtitle class="font-12" style="font-size: 24px;">Total Paid</v-list-item-subtitle>
                                        <v-spacer></v-spacer>
                                        <v-list-item-title class="primary--text" style="font-size: 15px !important;text-align: right;font-weight: 600;">
                                            {{$store.getters.convertToCurrencyNoFrac(summaryInv ? summaryInv.amt_paid : 0)}}
                                        </v-list-item-title>
                                    </div>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item class="pl-0 pr-0" style="min-height: 40px !important;">
                                <v-list-item-content class="pt-0 pb-0">
                                    <div class="d-flex">
                                        <v-list-item-subtitle class="font-12" style="font-size: 24px;">Total Paid Discount</v-list-item-subtitle>
                                        <v-spacer></v-spacer>
                                        <v-list-item-title class="primary--text" style="font-size: 15px !important;text-align: right;font-weight: 600;">
                                            {{$store.getters.convertToCurrencyNoFrac(summaryInv ? summaryInv.amt_paid_disc : 0)}}
                                        </v-list-item-title>
                                    </div>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item class="pl-0 pr-0" style="min-height: 40px !important;">
                                <v-list-item-content class="pt-0 pb-0">
                                    <div class="d-flex">
                                        <v-list-item-subtitle class="font-12" style="font-size: 24px;">Amount Total</v-list-item-subtitle>
                                        <v-spacer></v-spacer>
                                        <v-list-item-title class="primary--text" style="font-size: 15px !important;text-align: right;font-weight: 600;">
                                            {{$store.getters.convertToCurrencyNoFrac(summaryInv ? summaryInv.amt_total : 0)}}
                                        </v-list-item-title>
                                    </div>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item class="pl-0 pr-0" style="min-height: 40px !important;">
                                <v-list-item-content class="pt-0 pb-0">
                                    <div class="d-flex">
                                        <v-list-item-subtitle class="font-12" style="font-size: 24px;">Total Hutang</v-list-item-subtitle>
                                        <v-spacer></v-spacer>
                                        <v-list-item-title class="red--text" style="font-size: 15px !important;text-align: right;font-weight: 600;">
                                            {{$store.getters.convertToCurrencyNoFrac(summaryInv ? (summaryInv.total_hutang > 0 ? summaryInv.total_hutang : 0) : 0)}}
                                        </v-list-item-title>
                                    </div>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col class="col-12" sm="6" md="4">
                <v-card outlined class="mt-1" height="360">
                    <v-card-text>
                        <v-img
                        class="text-center mt-12 mx-auto"
                        lazy-src="@/assets/empty_data.png"
                        max-width="200"
                        src="@/assets/empty_data.png"
                        v-if="showData"
                        ></v-img>
                        <div id="container1" style="height: 300px; width: 100%;"></div>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col class="col-12" sm="6" md="4">
                <v-card outlined class="mt-1" height="360">
                    <v-card-text>
                        <v-img
                        class="text-center mt-12 mx-auto"
                        lazy-src="@/assets/empty_data.png"
                        max-width="200"
                        src="@/assets/empty_data.png"
                        v-if="showData"
                        ></v-img>
                        <div id="container2" style="height: 300px; width: 100%;"></div>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col class="col-12 mb-12" sm="12" md="12">
                <v-card outlined>
                    <v-data-table
                        dense
                        :headers="headers"
                        :items="raw_materials"
                        class="elevation-1"
                        :items-per-page="30"
                        :footer-props="{
                            'items-per-page-options': [10, 20, 30, 40, 50, -1]
                        }"
                        :loading="$store.state.overlay"
                        height="420"
                        fixed-header
                        :divider="true"
                        :light="true"
                        :search="searchItemTrans" 
                        :item-class="tr_datatable"
                    >    
                        <template v-slot:top>
                            <v-toolbar flat color="white" class="mt-2 mb-2">
                                <div class="d-flex w-100">
                                    <h6 class="indigo-text m-0 primary--text"><v-icon color="primary" class="">mdi-table</v-icon> List Vendor </h6>
                                    <v-spacer></v-spacer>
                                    <v-text-field solo style="max-width: 350px;" class="border-12 mr-3" v-model="searchItemTrans" append-icon="mdi-magnify" label="Search" dense single-line hide-details></v-text-field>
                                    <v-btn
                                    small
                                    color="#005c37"
                                    class="py-5 mr-3 text-white border-12"
                                    >
                                        <v-icon>mdi-file-excel</v-icon>
                                        <download-excel
                                            class="text"
                                            :fetch           = "exportExcel"
                                            :data="hutang_items"
                                            :before-generate = "startDownload"
                                            :before-finish   = "finishDownload">
                                            Export Excel
                                        </download-excel>
                                    </v-btn>
                                </div>
                            </v-toolbar>
                        </template>
                        <template v-slot:[`item.dt_po`]="{ item }">
                            {{ (new Date(new Date(item.dt_po) - (new Date()).getTimezoneOffset() *
                    60000)).toISOString().substr(0, 10) }}
                        </template>
                        <template v-slot:[`item.total_inv`]="{ item }">
                            {{$store.getters.convertToCurrencyUs(item.total_inv)}}
                        </template>
                        <template v-slot:[`item.amt_total`]="{ item }">
                            {{$store.getters.convertToCurrencyUs(item.amt_total)}}
                        </template>
                        <template v-slot:[`item.amt_paid`]="{ item }">
                            {{$store.getters.convertToCurrencyUs(item.amt_paid)}}
                        </template>
                        <template v-slot:[`item.total_hutang`]="{ item }">
                            {{$store.getters.convertToCurrencyUs(item.total_hutang)}}
                        </template>
                        <template v-slot:[`item.actions`]="{ item }">
                            <v-menu
                                bottom
                                left
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        dark
                                        icon
                                        v-bind="attrs"
                                        v-on="on"
                                        color="grey"
                                        class="text-center"
                                    >
                                        <v-icon>mdi-dots-vertical</v-icon>
                                    </v-btn>
                                </template>

                                <v-list dense>
                                    <v-list-item @click="getDetail(item)">
                                        <v-list-item-icon>
                                            <v-icon>mdi-magnify</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title>Detail</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </template>
                    </v-data-table>
                </v-card>
                <v-dialog
                    v-model="dialog"
                    persistent
                    fullscreen
                    hide-overlay
                    transition="dialog-bottom-transition"
                    style="border-radius: 0 !important"
                >
                    <v-card class="p-2 rounded-l">
                        <v-card-title class="text-center">
                            <v-row>
                                <v-col cols="12">
                                    <div class="d-flex">
                                        <div class="ml-5">
                                            Detail
                                        </div>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            class="mx-2"
                                            fab
                                            x-small
                                            color="#e83e8c;"
                                            @click="[dialog = false]"
                                        >
                                            <v-icon dark>
                                                mdi-close
                                            </v-icon>
                                        </v-btn>
                                    </div>
                                </v-col>
                                <v-divider></v-divider>
                            </v-row>
                        </v-card-title>
                        <v-card-text>
                            <v-card outlined>
                                <v-container fluid>
                                    <v-row>
                                        <v-col class="col-12" sm="12" md="12">
                                            <table width="100%" cellspacing="0" cellpadding="0" style="width: 100%; margin: 5px 0;">
                                                <tbody>
                                                    <tr style="margin-top: 8px; margin-bottom: 8px;">
                                                        <td style="width:15%; padding-left: 5px;">
                                                            Vendor
                                                        </td>
                                                        <td style="width:5%">
                                                            :
                                                        </td>
                                                        <td style="width:30%">
                                                            {{ detail ? detail.vendor_name : '' }}
                                                        </td>
                                                        <td style="width:15%; padding-left: 5px;">
                                                            Total Invoice
                                                        </td>
                                                        <td style="width:5%">
                                                            :
                                                        </td>
                                                        <td style="width:30%">
                                                            {{ detail ? $store.getters.convertToCurrencyUs(detail.total_inv) : '' }}
                                                        </td>
                                                    </tr>
                                                    <tr style="margin-top: 8px; margin-bottom: 8px;">
                                                        <td style="width:15%; padding-left: 5px;">
                                                            Total Inv Paid
                                                        </td>
                                                        <td style="width:5%">
                                                            :
                                                        </td>
                                                        <td style="width:30%">
                                                            {{ detail ? $store.getters.convertToCurrencyUs(detail.total_inv_paid) : '' }}
                                                        </td>
                                                        <td style="width:15%; padding-left: 5px;">
                                                            Total Inv Hutang
                                                        </td>
                                                        <td style="width:5%">
                                                            :
                                                        </td>
                                                        <td style="width:30%">
                                                            {{ detail ? $store.getters.convertToCurrencyUs(detail.total_inv_hutang) : '' }}
                                                        </td>
                                                    </tr>
                                                    <tr style="margin-top: 8px; margin-bottom: 8px;">
                                                        <td style="width:15%; padding-left: 5px;">
                                                            Total Paid
                                                        </td>
                                                        <td style="width:5%">
                                                            :
                                                        </td>
                                                        <td style="width:30%">
                                                            {{ detail ? $store.getters.convertToCurrencyUs(detail.amt_paid) : '' }}
                                                        </td>
                                                        <td style="width:15%; padding-left: 5px;">
                                                            Total Paid Discount
                                                        </td>
                                                        <td style="width:5%">
                                                            :
                                                        </td>
                                                        <td style="width:30%">
                                                            {{ detail ? $store.getters.convertToCurrencyUs(detail.amt_paid_disc) : '' }}
                                                        </td>
                                                    </tr>
                                                    <tr style="margin-top: 8px; margin-bottom: 8px;">
                                                        <td style="width:15%; padding-left: 5px;">
                                                            Amount Total
                                                        </td>
                                                        <td style="width:5%">
                                                            :
                                                        </td>
                                                        <td style="width:30%">
                                                            {{ detail ? $store.getters.convertToCurrencyUs(detail.amt_total) : '' }}
                                                        </td>
                                                        <td style="width:15%; padding-left: 5px;">
                                                            Total Hutang
                                                        </td>
                                                        <td style="width:5%">
                                                            :
                                                        </td>
                                                        <td style="width:30%">
                                                            {{ detail ? $store.getters.convertToCurrencyUs(detail.total_hutang) : '' }}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </v-col>
                                        <v-col class="col-12 mt-3" sm="6" md="6">
                                            <v-data-table
                                                dense
                                                :headers="header_items"
                                                :items="items"
                                                class="elevation-1"
                                                :items-per-page="30"
                                                :footer-props="{
                                                    'items-per-page-options': [10, 20, 30, 40, 50, -1]
                                                }"
                                                :loading="$store.state.overlay"
                                                height="475"
                                                fixed-header
                                                :divider="true"
                                                :light="true"
                                                :search="searchItem" 
                                                :item-class="tr_datatable_detail"
                                                @click:row="showPayment"
                                            >    
                                                <template v-slot:top>
                                                    <v-toolbar flat color="white" class="mt-2 mb-2">
                                                        <div class="d-flex w-100">
                                                            <h6 class="indigo-text m-0 primary--text"><v-icon color="primary" class="">mdi-table</v-icon> List Item </h6>
                                                            <v-spacer></v-spacer>
                                                            <v-text-field solo style="max-width: 350px;" class="border-12 mr-3" v-model="searchItem" append-icon="mdi-magnify" label="Search" dense single-line hide-details></v-text-field>
                                                        </div>
                                                    </v-toolbar>
                                                </template>
                                                <template v-slot:[`item.dt_inv`]="{ item }">
                                                    {{ (new Date(new Date(item.dt_inv) - (new Date()).getTimezoneOffset() *
                                            60000)).toISOString().substr(0, 10) }}
                                                </template>
                                                <template v-slot:[`item.dt_due`]="{ item }">
                                                    {{ item.dt_due ? (new Date(new Date(item.dt_due) - (new Date()).getTimezoneOffset() *
                                            60000)).toISOString().substr(0, 10) : '' }}
                                                </template>
                                                <template v-slot:[`item.dt_tukar_tt`]="{ item }">
                                                    {{item.dt_tukar_tt ? (new Date(new Date(item.dt_tukar_tt) - (new Date()).getTimezoneOffset() *
                                            60000)).toISOString().substr(0, 10) : ''}}
                                                </template>
                                                <template v-slot:[`item.total_inv`]="{ item }">
                                                    {{$store.getters.convertToCurrencyUs(item.total_inv)}}
                                                </template>
                                                <template v-slot:[`item.amt_total`]="{ item }">
                                                    {{$store.getters.convertToCurrencyUs(item.amt_total)}}
                                                </template>
                                                <template v-slot:[`item.amt_paid`]="{ item }">
                                                    {{$store.getters.convertToCurrencyUs(item.amt_paid)}}
                                                </template>
                                                <template v-slot:[`item.amt_paid_disc`]="{ item }">
                                                    {{$store.getters.convertToCurrencyUs(item.amt_paid_disc)}}
                                                </template>
                                                <template v-slot:[`item.hutang`]="{ item }">
                                                    {{$store.getters.convertToCurrencyUs(item.hutang)}}
                                                </template>
                                                <template v-slot:[`item.actions`]="{ item }">
                                                    <v-menu
                                                        bottom
                                                        left
                                                    >
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-btn
                                                                dark
                                                                icon
                                                                v-bind="attrs"
                                                                v-on="on"
                                                                color="grey"
                                                                class="text-center"
                                                            >
                                                                <v-icon>mdi-dots-vertical</v-icon>
                                                            </v-btn>
                                                        </template>

                                                        <v-list dense>
                                                            <v-list-item @click="getDetail(item)">
                                                                <v-list-item-icon>
                                                                    <v-icon>mdi-magnify</v-icon>
                                                                </v-list-item-icon>
                                                                <v-list-item-content>
                                                                    <v-list-item-title>Detail</v-list-item-title>
                                                                </v-list-item-content>
                                                            </v-list-item>
                                                        </v-list>
                                                    </v-menu>
                                                </template>
                                            </v-data-table>
                                        </v-col>
                                        <v-col class="col-12 mt-3" sm="6" md="6">
                                            <v-data-table
                                                dense
                                                :headers="header_item_payments"
                                                :items="payment_items"
                                                class="elevation-1"
                                                :items-per-page="30"
                                                :footer-props="{
                                                    'items-per-page-options': [10, 20, 30, 40, 50, -1]
                                                }"
                                                :loading="$store.state.overlay"
                                                height="475"
                                                fixed-header
                                                :divider="true"
                                                :light="true"
                                                :search="searchItemPayment" 
                                                :item-class="tr_datatable"
                                            >    
                                                <template v-slot:top>
                                                    <v-toolbar flat color="white" class="mt-2 mb-2">
                                                        <div class="d-flex w-100">
                                                            <h6 class="indigo-text m-0 primary--text"><v-icon color="primary" class="">mdi-table</v-icon> List Payment </h6>
                                                            <v-spacer></v-spacer>
                                                            <v-text-field solo style="max-width: 350px;" class="border-12 mr-3" v-model="searchItemPayment" append-icon="mdi-magnify" label="Search" dense single-line hide-details></v-text-field>
                                                        </div>
                                                    </v-toolbar>
                                                </template>
                                                <template v-slot:[`item.dt_pay`]="{ item }">
                                                    {{ (new Date(new Date(item.dt_pay) - (new Date()).getTimezoneOffset() *
                                            60000)).toISOString().substr(0, 10) }}
                                                </template>
                                                <template v-slot:[`item.local_inv_amt`]="{ item }">
                                                    {{$store.getters.convertToCurrencyUs(item.local_inv_amt)}}
                                                </template>
                                                <template v-slot:[`item.local_pay_amt`]="{ item }">
                                                    {{$store.getters.convertToCurrencyUs(item.local_pay_amt)}}
                                                </template>
                                                <template v-slot:[`item.local_disc_amt`]="{ item }">
                                                    {{$store.getters.convertToCurrencyUs(item.local_disc_amt)}}
                                                </template>
                                            </v-data-table>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card>
                        </v-card-text>
                    </v-card>
                </v-dialog>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import {backendApi} from "@/backend-api/backend-api-sr"

export default {
    data() {
        return {
            snackbar: {
                color: null,
                icon: null,
                mode: null,
                position: "top",
                text: null,
                timeout: 7500,
                title: null,
                visible: false
            },
            breadcumbs: [
                {
                text: 'SMS',
                disabled: false,
                href: '/admin/sms',
                },
                {
                text: 'Finance Report',
                disabled: true,
                href: 'breadcrumbs_link_2',
                },
                {
                text: 'Hutang Report',
                disabled: true,
                href: 'breadcrumbs_link_2',
                },
            ],
            loading:false,
            offices: [{
                office_id : 'SMS',
                office : 'STARCON'
            }],
            office: 'SMS',
            vendors: [],
            vendor_name:'',
            paid_flag:'',
            vendor:'',
            status:'',
            statuses:[
             {
                    status: 'N',
                    value: 'O'
                },
                {
                    status: 'Y',
                    value: 'C'
                }    

            ],
            saless:[],
            sales: '',
            customers:[],
            items:[],
            customer: '',
            dialog: false,
            dialog2 : false,
            search_detail: '',
             header_po_items:[
                {
                    text: 'Vendor Name',
                    align: 'start',
                    sortable: true,
                    value: 'vendor_name',
                },
                { text: 'TR ID', value: 'tr_id' },
                { text: 'Invoice ID', value: 'inv_id' },
                { text: 'DT Invoice', value: 'dt_inv' },
                { text: 'Due Date', value: 'dt_due' },
                { text: 'Currency', value: 'curr_id' },
                { text: 'Ex Rate', value: 'exchange_rate' },
                { text: 'Amt Total', value: 'amt_total' },
                { text: 'Amt Paid Disc', value: 'amt_paid_disc' },
                { text: '', value: 'actions' }
            ],
            loadingCust: false,
            display: 'none',
            totalItem_po_items: 10,
            po_itemsDetail: [],
            option_po_items: {},
            loading3: false,
            loading4: false,
            loading5: false,
            loading_loader: false,
            sortBy: 'asc',
            sortName: '',
            raw_materials: [],
            search: null,
            vendorIdDetail:'',
            headers: [                
                { text: 'Vendor Name', value: 'vendor_name', width:200, align: 'start' },
                {
                    text: 'Total Inv',
                    sortable: false,
                    value: 'total_inv',
                    align: 'right',
                    width:100
                },
                { text: 'Total Inv Paid', value: 'total_inv_paid', width:100,  align: 'right' },
                { text: 'Total Inv Hutang', value: 'total_inv_hutang', width:120,  align: 'right' },
                { text: 'Amt Total', value: 'amt_total', width:200,  align: 'right' },
                { text: 'Amt Paid', value: 'amt_paid', width:200, align: 'right' },
                { text: 'Amt Hutang', value: 'total_hutang', width:200, align: 'right' },
                { text: '',  value: 'actions', width:50 },
               
            ],
            totalItems: 10,
            pagination: 1,
            options: {},
            optionsDetail:{},
            totalItemsSummary:10,
            paginationSummary:1,
            totalItemsDetail:10,
            paginationDetail:1,
            order_id: '',
            no_faktur: '',
            date_from: '',
            modal: false,
            date_to: '',
            modal_to: false,
            invoice: 'byDateInv',
            summaryInv: null,
            header_po_items_payment:[
                { text: 'PAY. DATE', value: 'dt_pay', sortable: false },
                { text: 'PAY. ID', value: 'pay_id', sortable: false },
                { text: 'DP. ID', value: 'dp_id', sortable: false },
                { text: 'USER', value: 'user_id', sortable: false },
                { text: 'REMARK', value: 'remark', sortable: false },
                { text: 'PAY. AMT.', value: 'pay_amt', sortable: false },                
            ],
            po_itemsDetailPayment: [],
            loading4: false,
            strInvID: '',
            opt_paid_flag:'',
            searchItemTrans: '',
            showData: true,
            raws: [
                {
                    raw: 'Raw Material Yes',
                    value: 'Y'
                },
                {
                    raw: 'Raw Material No',
                    value: 'N'
                }
            ],
            raw: '',
            detail: null,
            header_items: [
                { text: 'TR ID', value: 'tr_id', width:120, align: 'start' },
                { text: 'Invoice', value: 'inv_id', width:120, align: 'left' },
                { text: 'Date Invoice', value: 'dt_inv', width:120, align: 'left' },
                { text: 'Paid Flag', value: 'paid_flag', width:100 },
                { text: 'Raw Flag', value: 'raw_flag', width:100 },
                { text: 'Date Due', value: 'dt_due', width:120, align: 'left' },
                { text: 'Date Tuker TT', value: 'dt_tukar_tt', width:120, align: 'left' },
                { text: 'Pay Term', value: 'pay_term_id', width:120, align: 'left' },
                { text: 'Hutang', value: 'hutang', width:120, align: 'right' },
                { text: 'Amt Paid', value: 'amt_paid', width:120, align: 'right' },
                { text: 'Amt Paid Disc', value: 'amt_paid_disc', width:120, align: 'right' },
                { text: 'Amt Total', value: 'amt_total', width:120, align: 'right' },

            ],
            items: [],
            searchItem: '',
            searchItemPayment: '',
            header_item_payments: [
                { text: 'Invoice', value: 'inv_id', width:120, align: 'start' },
                { text: 'Date Pay', value: 'dt_pay', width:120, align: 'left' },
                { text: 'Pay ID', value: 'pay_id', width:120, align: 'left' },
                { text: 'DP ID', value: 'dp_id', width:120, align: 'left' },
                { text: 'Remark', value: 'remark', width:300, align: 'left' },
                { text: 'Currency', value: 'curr_id', width:120, align: 'left' },
                { text: 'Amt Invoice', value: 'local_inv_amt', width:120, align: 'right' },
                { text: 'Amt Pay', value: 'local_pay_amt', width:120, align: 'right' },
                { text: 'Amt Discount', value: 'local_disc_amt', width:120, align: 'right' },
            ],
            payment_items: [],
            payment_item: null,
            hutang_items: []
        }
    },
    async mounted(){
        this.$store.dispatch('setOverlay', true) 
        await this.updateDepartment()
        this.$store.dispatch('setOverlay', false)
    },
    methods:{
        tr_datatable(item) {
            var rowClass = 'tr_datatable'
            return rowClass;
        },
        tr_datatable_detail(item) {
            var rowClass = 'tr_datatable';
            if (this.payment_item) {
                if(this.payment_item.tr_id === item.tr_id && this.payment_item.inv_id === item.inv_id){
                    rowClass = 'tr_choice'
                } else {
                    rowClass = 'tr_datatable'
                }
            } else {
                rowClass = 'tr_datatable'
            }
            return rowClass;
        },
        getEnvConf(){
            let data = this.data_var;

            this.$store.dispatch("getVariabelUser", { data }).then(res => {

                for (let i = 0; i < res.data.data.length; i++) {

                    if (res.data.data[i]['var_id'] === 'APRVPR1') {
                        if (res.data.data[i]['var_value'] != null) {
                            this.user_approve1 = res.data.data[i]['var_value']
                        }
                    }
                    
                    if (res.data.data[i]['var_id'] === 'APRVPR2') {
                        if (res.data.data[i]['var_value'] != null) {
                            this.user_approve2 = res.data.data[i]['var_value']
                        }
                    }

                    if (res.data.data[i]['var_id'] === 'RAW') {
                        if (res.data.data[i]['var_value'] != null) {
                            this.raw = res.data.data[i]['var_value']
                            this.disabled_search = true
                        }
                    }

                }

            })
        },
        async updateDepartment(vendor) {
            try {
                const res = await axios.post(
                    `${process.env.VUE_APP_URL}/api/master/starcon/showVendor`,
                    { vendor }, 
                    {
                        headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` }
                    }
                );
                this.vendors = res.data; 
            } catch (error) {
                console.error('Error updating department:', error);
            } finally {
                this.loading2 = false; 
            }
        },       
        async submit(){
            this.loading = true
            this.loading_loader = true
            this.display = 'block'
            this.$store.dispatch('setOverlay', true) 
            await this.summary()
            await this.summaryHutang()
            this.$store.dispatch('setOverlay', false)
        },
        async summaryHutang(){
            this.loading = true
            this.loading_loader = true
            var start_date = this.date_from ? new Date(this.date_from).toISOString().substr(0, 10).replace(/-/g,"") : ''
            var end_date = this.date_to ? new Date(this.date_to).toISOString().substr(0, 10).replace(/-/g,"") : ''
            await axios.get(`${process.env.VUE_APP_URL}/api/starcon/finance/hutang/getSummaryHutangV2?vendor=${this.vendor_name ? this.vendor_name : ""}&paid=${this.paid_flag ? this.paid_flag : ''}&dt_start=${start_date ? start_date : ""}&dt_end=${end_date ? end_date : ""}&byWhat=${this.invoice ? this.invoice : ''}&category=${this.raw ? this.raw : ''}`,  { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                console.log(res.data)
                console.log('summary')
                this.loading = false
                this.loading_loader = false
                this.raw_materials = res.data.data
            })
        },
        async summary(){
            var start_date = this.date_from ? new Date(this.date_from).toISOString().substr(0, 10).replace(/-/g,"") : ''
            var end_date = this.date_to ? new Date(this.date_to).toISOString().substr(0, 10).replace(/-/g,"") : ''

            await axios.get(`${process.env.VUE_APP_URL}/api/starcon/finance/hutang/GetTotalSummaryV2?vendor=${this.vendor_name ? this.vendor_name : ""}&paid=${this.paid_flag ? this.paid_flag : ''}&dt_start=${start_date ? start_date : ""}&dt_end=${end_date ? end_date : ""}&byWhat=${this.invoice ? this.invoice : ''}&category=${this.raw ? this.raw : ''}`,  { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.summaryInv = res.data.data.length > 0 ? res.data.data[0] : NULL
                // console.log(res.data.data);
                var dt_detail =  [];
                var label_detail = 'Total Invoice';
                dt_detail.push({  label: this.summaryInv ? 'Inv Paid' : '',  y: parseInt(this.summaryInv ? this.summaryInv.total_inv_paid : 0), legendText: this.summaryInv ? 'Inv Paid' : '' });
                dt_detail.push({  label: this.summaryInv ? 'Inv Hutang' : '',  y: parseInt(this.summaryInv ? this.summaryInv.total_inv_hutang : 0), legendText: this.summaryInv ? 'Inv Hutang' : '' });
                var container_detail = 'container1';
                var title_detail = 'by Total Invoice'
                this.getPieChart(dt_detail, container_detail, title_detail, label_detail, false)


                var dt_detail_pay = [];
                var label_detail_pay = 'Total Pembayaran';
                dt_detail_pay.push({  
                    label: this.summaryInv ? 'Amount Paid' : '',  
                    y: parseFloat(this.summaryInv ? this.summaryInv.amt_paid : 0), 
                    legendText: this.summaryInv ? 'Amount Paid' : '',
                    toolTipContent: `Amount Paid: ${parseFloat(this.summaryInv.amt_paid).toLocaleString('id-ID', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}` // Format tooltip dengan koma
                });
                dt_detail_pay.push({  
                    label: this.summaryInv ? 'Amount Hutang' : '',  
                    y: parseFloat(this.summaryInv ? this.summaryInv.total_hutang : 0), 
                    legendText: this.summaryInv ? 'Amount Hutang' : '',
                    toolTipContent: `Amount Hutang: ${parseFloat(this.summaryInv.total_hutang).toLocaleString('id-ID', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}` // Format tooltip dengan koma
                });
                var container_detail_pay = 'container2';
                var title_detail_pay = 'by Total Pembayaran';
                this.getPieChart(dt_detail_pay, container_detail_pay, title_detail_pay, label_detail_pay, false);

                this.showData = false
            })
        },
        async getDetail(item){
            this.payment_items = []
            this.items = []
            this.$store.dispatch('setOverlay', true) 
            this.detail = item
            var start_date = this.date_from ? new Date(this.date_from).toISOString().substr(0, 10).replace(/-/g,"") : ''
            var end_date = this.date_to ? new Date(this.date_to).toISOString().substr(0, 10).replace(/-/g,"") : ''

            await axios.get(`${process.env.VUE_APP_URL}/api/starcon/finance/hutang/getDetailHutangV2?vendor=${item.vendor_id ? item.vendor_id : ""}&paid=${this.paid_flag ? this.paid_flag : ''}&dt_start=${start_date ? start_date : ""}&dt_end=${end_date ? end_date : ""}&byWhat=${this.invoice ? this.invoice : ''}&category=${this.raw ? this.raw : ''}`,  { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                console.log(res.data.data);
                this.items = res.data.data
                this.dialog = true
                this.$store.dispatch('setOverlay', false) 

            })
        },
        async showPayment(item){
            this.payment_item = item
            this.payment_items = []
            this.$store.dispatch('setOverlay', true) 
            await axios.get(`${process.env.VUE_APP_URL}/api/starcon/finance/hutang/getSummaryPaymentV2?inv_id=${item.inv_id ? item.inv_id : ""}`,  { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                console.log(res.data);
                this.payment_items = res.data.data
                this.$store.dispatch('setOverlay', false) 
            });
        },
        getPieChart(dt_detail, container_detail, title_detail, label_detail, destroy = false){
            var pie_chart = new CanvasJS.Chart(container_detail, {
                animationEnabled: true,
                exportEnabled: true,
                theme: "light2",
                exportEnabled: true,
                title: {
                    text: title_detail,
                    fontFamily: "Calibri",
                    fontSize: 20
                },
                exportEnabled: true,
                subtitles:[
                    {
                        text: label_detail,
                        //Uncomment properties below to see how they behave
                        fontFamily: "Calibri",
                        fontColor: "red",
                        fontSize: 12
                    }
                ],
                legend: {
                    itemclick: this.toggleDataPointVisibility
                },
                data: [{

                    type: "pie",
                    percentFormatString: "#0.##",
                    indexLabel: "{label} #percent%",
                    indexLabelFontSize: 12,

                }]
            });

            pie_chart.options.data[0].dataPoints = dt_detail;
            this.showDefaultText(pie_chart, "No Data Found!");
            var x = window.matchMedia("(max-width: 991px)");

            if (destroy == true) {
                pie_chart.destroy();
            } else {
                if (x.matches) {

                    for(var i = 0; i < pie_chart.options.data.length; i++){
                        pie_chart.options.data[i].indexLabelFontSize = 6;
                    }
                    pie_chart.render();
                }

                pie_chart.render();
            }

            
        },
        toggleDataSeries(e) {
            if (typeof (e.dataSeries.visible) === "undefined" || e.dataSeries.visible) {
                e.dataSeries.visible = false;
            } else {
                e.dataSeries.visible = true;
            }
            e.chart.render();
        },
        toggleDataPointVisibility(e) {
            if(e.dataPoint.hasOwnProperty("actualYValue") && e.dataPoint.actualYValue !== null) {
                e.dataPoint.y = e.dataPoint.actualYValue;
                e.dataPoint.actualYValue = null;
                e.dataPoint.indexLabelFontSize = null;
                e.dataPoint.indexLabelLineThickness = null;
                e.dataPoint.legendMarkerType = "circle";
            } 
            else {
                e.dataPoint.actualYValue = e.dataPoint.y;
                e.dataPoint.y = 0;
                e.dataPoint.indexLabelFontSize = 0;
                e.dataPoint.indexLabelLineThickness = 0; 
                e.dataPoint.legendMarkerType = "cross";
            }
                e.chart.render();
        },
        showDefaultText(chart, text) {
            var dataPoints = chart.options.data[0].dataPoints;
            var isEmpty = !(dataPoints && dataPoints.length > 0);

            if (!isEmpty) {
                for (var i = 0; i < dataPoints.length; i++) {
                isEmpty = !dataPoints[i].y;
                if (!isEmpty)
                    break;
                }
            }

            if (!chart.options.subtitles)
                chart.options.subtitles = [];
            if (isEmpty) {
                chart.options.subtitles.push({
                text: text,
                verticalAlign: 'center',
                });
                chart.options.data[0].showInLegend = false;
            } else {
                chart.options.data[0].showInLegend = true;
            }
        },
        async exportExcel(){
            var start_date = this.date_from ? new Date(this.date_from).toISOString().substr(0, 10).replace(/-/g,"") : ''
            var end_date = this.date_to ? new Date(this.date_to).toISOString().substr(0, 10).replace(/-/g,"") : ''

            const respData = await backendApi.fetchCore(`/api/sr/hutang/getDetailHutangV2?vendor=${this.vendor_name ? this.vendor_name : ""}&paid=${this.paid_flag ? this.paid_flag : ''}&dt_start=${start_date ? start_date : ""}&dt_end=${end_date ? end_date : ""}&byWhat=${this.invoice ? this.invoice : ''}&category=${this.raw ? this.raw : ''}`, '', false, false, false)

            if (respData.status === 200){
                this.hutang_items = respData.data.data
                return this.hutang_items
            }
        },
        startDownload(){this.$store.dispatch('setOverlay', true)},
        finishDownload(){this.$store.dispatch('setOverlay', false)},
    },
    watch: {
        // options: {
        //     handler () {
        //         const { page, itemsPerPage } = this.options
        //         if (this.display != 'none') {
        //             this.summaryHutang(page, itemsPerPage)
        //         }
        //     },
        //     deep: true
        // },
        // optionsDetail: {
        //     handler () {
        //         const { page, itemsPerPage } = this.optionsDetail
        //         if (this.display != 'none') {
        //             this.getPullDataDetailDalam(page, itemsPerPage, this.vendorIdDetail)
        //         }
        //     },
        //     deep: true
        // },
        search (val) {
            val && val !== this.customer && this.querySelections(val)
        },
    }
}
</script>
<style scoped>
.theme--light.v-data-table {
    background-color: #ffffff;
    color: rgba(0, 0, 0, 0.87);
}
</style>
